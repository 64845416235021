<!-- /adultEducation/teacher/ActivityDetails   老师活动详情-->
<template>
  <div style="height: 100%">
    <div>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-back"
        @click="go_look('', '', '', '', 3)"
        style="margin-bottom: 10px"
      >返回</el-button>
    </div>
    <el-card class="box-card">
      <div class="content">
        <p>学生基本情况表</p>
        <div class="table" style="margin-bottom: 20px">
          <el-scrollbar style="height: 100%">
            <el-table
              @cell-click="
                (row, column, cell, event) =>
                  go_look(row, column, cell, event, 1)
              "
              :data="studentData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F3F7FF',
                color: '#0B1B31',
                fontSize: '14px',
                fontWeight: '500',
              }"
            >
              <el-table-column prop="userId" label="用户名" width="180" align="center"></el-table-column>
              <el-table-column prop="fullName" label="姓名" align="center"></el-table-column>
              <el-table-column prop="lastReferCaseTime" label="最近一次答题时间" align="center"></el-table-column>
              <el-table-column prop="caseTotal" label="任务量" align="center"></el-table-column>
              <el-table-column prop="referCaseCount" label="涉及变式数" align="center"></el-table-column>
              <!-- <el-table-column prop="avgAccuracy" label="正确率">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.avgAccuracy ? scope.row.avgAccuracy + "%" : ""
                    }}
                  </div>
                </template>
              </el-table-column>-->
              <el-table-column prop="avgAccuracy" label="内化率" align="center">
                <template slot-scope="scope">
                  <div>
                    {{
                    scope.row.avgAccuracy!==null ? scope.row.avgAccuracy + "%" : "暂无"
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <el-pagination
          @size-change="(val) => handleChange(val, 'studentSize')"
          @current-change="(val) => handleChange(val, 'studentPage')"
          :current-page="page.studentPage"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="page.studentSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.studentTotal"
        ></el-pagination>
      </div>
      <div class="content" style="margin-top: 20px">
        <p>变式基本情况表</p>
        <div class="table">
          <el-scrollbar style="height: 100%">
            <el-table
              :data="variantData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F3F7FF',
                color: '#0B1B31',
                fontSize: '14px',
                fontWeight: '500',
              }"
              @cell-click="go_look(row, column, cell, event, 2)"
            >
              <el-table-column prop="caseSerial" label="变式编号" width="180" align="center"></el-table-column>
              <el-table-column prop="caseName" label="变式名称" align="center"></el-table-column>
              <el-table-column prop="accomplishUserCount" label="练习人数" align="center"></el-table-column>
              <el-table-column prop="accomplishTotal" label="练习次数" align="center"></el-table-column>
              <el-table-column prop="avgAccuracy" label="平均内化率" align="center">
                <template slot-scope="scope">
                  <div>
                    {{
                    scope.row.avgAccuracy!==null ? scope.row.avgAccuracy + "%" : "暂无"
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop label></el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <el-pagination
          @size-change="(val) => handleChange(val, 'variantSize')"
          @current-change="(val) => handleChange(val, 'variantPage')"
          :current-page="page.variantPage"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="page.variantSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.variantTotal"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  themeTeacherGetActivityUserPage,
  themeTeacherGetActivityCasePage,
} from "../../../api/api";

export default {
  data () {
    return {
      page: {
        studentPage: 1,
        studentSize: 20,
        studentTotal: 400,
        variantPage: 1,
        variantSize: 20,
        variantTotal: 400,
      },
      tableData: [],
      studentData: [],
      variantData: [],
      activityId: null,
    };
  },

  components: {},
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.activityId = this.$route.query.activityId;
      this.themeTeacherGetActivityUserPage();
      this.themeTeacherGetActivityCasePage();
    },
    themeTeacherGetActivityUserPage () {
      themeTeacherGetActivityUserPage({
        pageNum: this.page.studentPage,
        pageSize: this.page.studentSize,
        query: {
          activityId: this.activityId,
        },
      }).then((res) => {

        res.data.list.map((item) => {
          if (item.lastReferCaseTime) {
            item.lastReferCaseTime = this.currentTime.formatDate(
              item.lastReferCaseTime * 1000
            );
          } else {
            item.lastReferCaseTime = '--'
          }
        });
        this.studentData = res.data.list;
        this.page.studentTotal = res.data.total;
      });
    },
    themeTeacherGetActivityCasePage (activityId) {
      themeTeacherGetActivityCasePage({
        pageNum: this.page.variantPage,
        pageSize: this.page.variantSize,
        query: {
          activityId: this.activityId,
        },
      }).then((res) => {
        this.variantData = res.data.list;
        this.page.variantTotal = res.data.total;
      });
    },
    go_look (row, column, cell, event, type) {

      if (type == 1) {
        // this.$router.push({ name: "adultActivityDetails" });
      } else if (type == 2) {

        // this.$router.push({ name: "adultSummary" });
      } else if (type == 3) {
        this.$router.go(-1)
      }
    },

    handleChange (val, name) {
      switch (name) {
        case "studentPage":
          this.page[name] = val;
          this.themeTeacherGetActivityUserPage();
          break;
        case "studentSize":
          this.page[name] = val;
          this.themeTeacherGetActivityUserPage();
          break;
        case "variantPage":
          this.page[name] = val;
          this.themeTeacherGetActivityCasePage();
          break;
        case "variantSize":
          this.page[name] = val;
          this.themeTeacherGetActivityCasePage();
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import './style/index.less';
</style>
