var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"mini","icon":"el-icon-back"},on:{"click":function($event){return _vm.go_look('', '', '', '', 3)}}},[_vm._v("返回")])],1),_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"content"},[_c('p',[_vm._v("学生基本情况表")]),_c('div',{staticClass:"table",staticStyle:{"margin-bottom":"20px"}},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.studentData,"header-cell-style":{
              background: '#F3F7FF',
              color: '#0B1B31',
              fontSize: '14px',
              fontWeight: '500',
            }},on:{"cell-click":function (row, column, cell, event) { return _vm.go_look(row, column, cell, event, 1); }}},[_c('el-table-column',{attrs:{"prop":"userId","label":"用户名","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"fullName","label":"姓名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"lastReferCaseTime","label":"最近一次答题时间","align":"center"}}),_c('el-table-column',{attrs:{"prop":"caseTotal","label":"任务量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"referCaseCount","label":"涉及变式数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"avgAccuracy","label":"内化率","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.avgAccuracy!==null ? scope.row.avgAccuracy + "%" : "暂无")+" ")])]}}])})],1)],1)],1),_c('el-pagination',{attrs:{"current-page":_vm.page.studentPage,"page-sizes":[5, 10, 20, 50],"page-size":_vm.page.studentSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.page.studentTotal},on:{"size-change":function (val) { return _vm.handleChange(val, 'studentSize'); },"current-change":function (val) { return _vm.handleChange(val, 'studentPage'); }}})],1),_c('div',{staticClass:"content",staticStyle:{"margin-top":"20px"}},[_c('p',[_vm._v("变式基本情况表")]),_c('div',{staticClass:"table"},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.variantData,"header-cell-style":{
              background: '#F3F7FF',
              color: '#0B1B31',
              fontSize: '14px',
              fontWeight: '500',
            }},on:{"cell-click":function($event){return _vm.go_look(_vm.row, _vm.column, _vm.cell, _vm.event, 2)}}},[_c('el-table-column',{attrs:{"prop":"caseSerial","label":"变式编号","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"caseName","label":"变式名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"accomplishUserCount","label":"练习人数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"accomplishTotal","label":"练习次数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"avgAccuracy","label":"平均内化率","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.avgAccuracy!==null ? scope.row.avgAccuracy + "%" : "暂无")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"","label":""}})],1)],1)],1),_c('el-pagination',{attrs:{"current-page":_vm.page.variantPage,"page-sizes":[5, 10, 20, 50],"page-size":_vm.page.variantSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.page.variantTotal},on:{"size-change":function (val) { return _vm.handleChange(val, 'variantSize'); },"current-change":function (val) { return _vm.handleChange(val, 'variantPage'); }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }